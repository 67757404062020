<template>
  <div class="input__w" :class="[{ wide }, { isLandingPage }]">
    <div class="input">
      <input
        v-model.trim="getValue"
        class="input__field"
        type="email"
        :disabled="disabled"
        autocomplete="email"
        :class="[
          { filled: getValue },
          { error: validate.$error && validate.$dirty },
        ]"
        @blur="onBlur"
      />
      <span class="input__caption" :class="{ required }">
        {{ _T(caption) }}
      </span>
      <div v-show="validate.$error && validate.$dirty" class="input__error-img">
        <img src="~/assets/icons/error-input.svg" alt="error attention" />
      </div>
    </div>
    <div class="input__error-caption-w">
      <p v-if="validate.$error && validate.$dirty">
        {{ validate.$errors[0].$message }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  caption: { type: String, required: true },
  modelValue: { type: String, required: false },
  validate: { type: Object, required: true },
  required: { type: Boolean, required: false, default: true },
  disabled: { type: Boolean, required: false, default: false },
  wide: { type: Boolean, required: false, default: false },
  isLandingPage: { type: Boolean, required: false, default: false },
});

const emits = defineEmits(["update:modelValue"]);

const getValue = computed({
  get: () => props.modelValue,
  set: (value) => emits("update:modelValue", value),
});

function onBlur() {
  props.validate.$touch();
}
</script>

<style lang="scss" scoped></style>
