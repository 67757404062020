export const useSingletonHandler = (handler) => {
  const isHandled = ref(false);

  const handle = async () => {
    if (isHandled.value) {
      return;
    }

    isHandled.value = true;
    await handler().finally(() => (isHandled.value = false));
  };

  return reactive({
    isHandled,
    handle,
  });
};
