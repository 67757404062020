import category_payload_kZ5iYdG8yz from "/usr/src/nuxt-app/plugins/category-payload.js";
import product_payload_COrjhJfO7x from "/usr/src/nuxt-app/plugins/product-payload.js";
import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/usr/src/nuxt-app/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_A0OWXRrUgq from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AUP22rrBAc from "/usr/src/nuxt-app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import vue_gtm_support_WsKmcNZPIU from "/usr/src/nuxt-app/plugins/vue-gtm-support.js";
import vue3_toastify_zPoFw264mR from "/usr/src/nuxt-app/plugins/vue3-toastify.js";
export default [
  category_payload_kZ5iYdG8yz,
  product_payload_COrjhJfO7x,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AUP22rrBAc,
  vue_gtm_support_WsKmcNZPIU,
  vue3_toastify_zPoFw264mR
]