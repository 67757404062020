export const useDiscountModalStore = defineStore("discountModalStore", {
  state() {
    return {
      isModalShown: false,
    };
  },
  actions: {
    setModalShown(isShown) {
      this.isModalShown = isShown;
    },
  },
  persist: {
    storage: persistedState.cookiesWithOptions({
      maxAge: 86400,
    }),
  },
});
