<template>
  <BaseModal
    :title="_T('@Subscription popup title')"
    :modal-width="620"
    title-color="white"
    without-header-border
    background-image="url('/img/discount-modal/percent_dark_green_bg.png')"
    header-background-color="rgba(255, 255, 255, 0.2)"
    close-button-color="white"
    @on-close-modal="onCloseModal"
  >
    <picture class="percent-icon percent-icon__robot">
      <source
        :srcset="'/img/discount-modal/percent_bg_center.png'"
        media="(max-width: 680px)"
      />
      <img src="/img/discount-modal/robot_percent.png" alt="percent" />
    </picture>

    <img
      class="percent-icon percent-icon__top-left"
      :src="'/img/discount-modal/percent_bg_top_left.png'"
      alt="percent"
    />

    <img
      class="percent-icon percent-icon__top-right"
      :src="'/img/discount-modal/percent_bg_top_right.png'"
      alt="percent"
    />

    <img
      class="percent-icon percent-icon__center"
      :src="'/img/discount-modal/percent_bg_center.png'"
      alt="percent"
    />

    <img
      class="percent-icon percent-icon__bottom"
      :src="'/img/discount-modal/percent_bg_bottom.png'"
      alt="percent"
    />

    <img
      class="percent-icon percent-icon__mobile"
      :src="'/img/discount-modal/percent_bg_top_right.png'"
      alt="percent"
    />
    <form action="" class="modal-discount">
      <p class="modal-discount__title">
        {{ _T("@Description subscription pop-up") }}
      </p>
      <InputText
        v-model="form.name"
        :validate="v.name"
        :maxlength="255"
        :caption="_T('@Name')"
        autocomplete-type="given-name"
      />
      <InputEmail v-model="form.email" :validate="v.email" caption="E-mail" />
      <BaseCheckbox
        v-model="form.isAgreementAccepted"
        :validate="v.isAgreementAccepted"
        color="white"
      >
        <span>
          {{ _T("@I agree with") }}
          <MyLink name="privacy-policy" target="_blank">
            {{ _T("@privacy policy") }}
          </MyLink>
        </span>
      </BaseCheckbox>
      <ButtonSecondary
        type="submit"
        :aria-label="_T('@Send')"
        button-width="363px"
        :disabled="sendForm.isHandled"
        @click.prevent="onSubmit"
      >
        {{ _T("@Send") }}
      </ButtonSecondary>
    </form>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~/modules/shared/inputs/InputText.vue";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import { useDiscountModalStore } from "~/store/discountModalStore";
import ButtonSecondary from "~/modules/shared/buttons/ButtonSecondary.vue";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";

const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();
const discountModalStore = useDiscountModalStore();

const { currentUser: user } = storeToRefs(userStore);

onMounted(() => {
  if (null !== user.value) {
    form.name = user.value.name.firstName;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "discount" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  name: "",
  email: "",
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 254),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

function onCloseModal() {
  discountModalStore.setModalShown(true);
}

const sendForm = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      event: "subscriptionWithCoupon",
      email: form.email,
      isConfirmed: true,
      name: form.name,
    },
  }).then(() => {
    closeModal();

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

const onSubmit = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await sendForm.handle();
};

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.modal-discount {
  position: relative;

  @include flex-container(column, center, center);
  gap: 24px;

  &__title {
    width: min(100%, 363px);
    text-align: center;

    @include font(18, 25, 700);
    letter-spacing: 0.36px;
    color: white;
  }
}

.percent-icon {
  position: absolute;

  &__robot {
    width: 250px;

    bottom: -5px;
    left: -18%;

    @include mobile {
      width: 114px;

      top: 520px;
      left: auto;
      right: 10px;
    }
  }

  &__top-left {
    width: 110px;

    top: 35px;
    left: -7%;

    @include mobile {
      top: 420px;
      left: 50%;

      transform: translateX(-50%);
    }
  }

  &__top-right {
    width: 120px;

    top: 50px;
    right: -8%;

    @include mobile {
      width: 109px;

      top: 2%;
      right: auto;
      left: -30px;
    }
  }

  &__center {
    width: 86px;

    top: 45%;
    right: 9%;

    @include mobile {
      top: 10px;
      right: 9%;
    }
  }

  &__bottom {
    width: 160px;

    bottom: -15%;
    right: -8%;

    @include mobile {
      top: 350px;
      right: auto;
      left: -40px;
    }
  }

  &__mobile {
    display: none;

    @include mobile {
      width: 120px;

      display: block;

      top: 350px;
      right: -20px;
    }
  }
}
</style>
